import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { auth, db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';

const Home = () => {
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(true);
  const [originalUrl, setOriginalUrl] = useState('');
  const [shortenedUrl, setShortenedUrl] = useState('');
  const [error, setError] = useState('');
  const apiEndpoint = 'https://mw9r01ssvf.execute-api.us-east-1.amazonaws.com/prod/shorten-url';

  useEffect(() => {
    const fetchUserName = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setUserName(userData.name || user.email);
        }
      }
      setLoading(false);
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserName();
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleShortenUrl = async () => {
    try {
      const response = await axios.post(apiEndpoint, { originalUrl });
      if (response.status === 200) {
        setShortenedUrl(response.data.shortUrl);
        setError('');
      } else {
        setError('Failed to shorten URL');
      }
    } catch (error) {
      console.error('Network Error:', error);
      setError('Failed to shorten URL');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="home-dashboard">
      <h1>Welcome, {userName}</h1>
      <div className="url-shortener">
        <input
          type="text"
          value={originalUrl}
          onChange={(e) => setOriginalUrl(e.target.value)}
          placeholder="Enter URL to shorten"
        />
        <button onClick={handleShortenUrl}>Shorten URL</button>
      </div>
      {shortenedUrl && (
        <div className="shortened-url">
          <p>Shortened URL: <a href={shortenedUrl} target="_blank" rel="noopener noreferrer">{shortenedUrl}</a></p>
        </div>
      )}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Home;
