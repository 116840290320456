import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Header from './Header';
import Signup from './Signup';
import UserDetails from './UserDetails';
import Home from './Home';
import Solutions from './Solutions';
import Contact from './Contact';
import NotFound from './NotFound';
import UploadBlogs from './UploadBlogs';
import Resources from './Resources';
import About from './AboutUs';
import PrivacyPolicy from './Privacy-Policy';
import Footer from './Footer';
import './App.css';
import homeImage from './homepng.png';
import Blog from './Blog';

const companies = [
  { name: 'Company A', icon: '🔹' },
  { name: 'Company B', icon: '🔹' },
  { name: 'Company C', icon: '🔹' },
  { name: 'Company D', icon: '🔹' },
  { name: 'Company E', icon: '🔹' },
  { name: 'Company F', icon: '🔹' },
  { name: 'Company G', icon: '🔹' },
  { name: 'Company H', icon: '🔹' },
  { name: 'Company I', icon: '🔹' },
  { name: 'Company J', icon: '🔹' },
];

const App = () => {
  const isDashboardRoute = window.location.pathname === '/home';

  return (
    <Router>
      <div className="App">
        {!isDashboardRoute && <Header />}
        <div className="content">
          <Routes>
            <Route path="/signup" element={<Signup />} />
            <Route path="/userdetails" element={<UserDetails />} />
            <Route path="/home" element={<Home />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/about" element={<About />} />
            <Route path="/" element={<DefaultPage />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
            <Route path="/pleaseuploadblogsfromhereneelesh" element={<UploadBlogs />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        {!isDashboardRoute && <Footer />}
      </div>
    </Router>
  );
}

const DefaultPage = () => (
  <main className="main-content">
    <Header />
    <div className="flex-container">
      <div className="text-content">
        <p className="heading">
          <span className="short-share">Short, Share</span> and <span className="earn-highlight">Earn</span>
        </p>
        <p className="description-text">
          Welcome to AffiChain, your ultimate solution for simplifying and monetizing your online links. Our URL shortening service not only makes your links more manageable but also provides opportunities to earn money every time your shortened URLs are clicked.
        </p>
        <Link to="/contact" className="contact-button">
          Contact Us
        </Link>
      </div>
      <div className="image-container">
        <img src={homeImage} alt="Home" className="home-image" />
      </div>
    </div>
    <hr className="divider" />
    <h2 className="subheading">TRUSTED BY YOUR Favourite Publishers and Link Distributors</h2>
    <hr className="divider" />
    <div className="companies-marquee">
      <div className="companies-container">
        {companies.concat(companies).map((company, index) => (
          <div key={index} className="company-item">
            <span className="company-icon">{company.icon}</span>
            <span className="company-name">{company.name}</span>
          </div>
        ))}
      </div>
    </div>
    <hr className="divider" />
    <p className="join-affichain">Join AffiChain: Your Path to Growth, Earnings, and Success</p>
    
    {/* Cards Section */}
    <div className="cards-container">
      <div className="card">
        <h3>Shorten URLs</h3>
        <p>Easily create concise, shareable links for better engagement and tracking.</p>
      </div>
      <div className="card">
        <h3>Earn Money Online</h3>
        <p>Generate income each time your shortened URLs are clicked by users.</p>
      </div>
      <div className="card">
        <h3>Make Money Online</h3>
        <p>Tap into the power of affiliate marketing and turn your online presence into a steady income stream.</p>
      </div>
      <div className="card">
        <h3>Affiliate Marketing</h3>
        <p>Leverage our tools to maximize your earnings through effective affiliate marketing strategies and detailed analytics.</p>
      </div>
    </div>
  </main>
);

export default App;
